.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #5c5e9a;
  /* background-color: #1B365C; */
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS */

.uppercontainer {
  min-height: 60vh !important;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.lowercontainer {
  min-height: 40vh !important;
  align-items: center;
  background-color: white !important;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.uppercontainerloadloop {
  min-height: 90vh !important;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.lowercontainerloadloop {
  min-height: 10vh !important;
  align-items: center;
  background-color: white !important;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.alert1 {
  min-width: 40% !important;
}

.beware {
  margin-top: 0.5rem;
  font-size: medium;
}

.bewaremm {
  margin-top: 0.5rem;
  font-size: small;
}

.redirect {
  margin-top: 1rem;
  font-size: small;
  margin-left: 10px;
}

.redirectmm {
  margin-top: 1rem;
  font-size: x-small;
  margin-left: 10px;
}

.spinnerload {
  margin-left: 0.2rem;
  width: 12px;
  height: 12px;
}

.searchbarimg {
  width: 25rem !important;
}

.checked {
  color: #282c34;
}

.nug-top-banner {
  min-height: 4vh !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #F0F0F0;
  font-size: 12px !important;
  color: #333F48 !important;
}

.nug-top-banner span:first-child {
  margin-right: 10px;
  margin-bottom: 2px;
}

.state-emblem {
  margin-right: 10px;
}

.nugtoptxt {
  color: #333F48 !important;
  text-decoration: none;
}

.header404 {
  width: 460px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 4rem;
}

.App404 {
  text-align: center;
  background-color: whitesmoke;
}

@media screen and (max-width: 767px) {
  .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .searchbarimg {
    width: 20rem !important;
  }

  .nug-top-banner {
    font-size: 12px !important;
    padding: 0.1rem 0.5rem !important;
  }
}

.spinnermargin {
  margin: 1rem 1rem;
}

.smalltext {
  font-size: large;
}


@media screen and (max-width: 600px) {
  .header404 {
    width: 280px;
    padding: 0 !important;
    min-height: 90vh;
  }

  .notfounded {
    width: 260px !important;
  }
}

.notfounded {
  width: 350px;
  min-height: auto;
}

.btn-primary {
  background-color: #2F3C87;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: #4A59A7;
}